var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar-brand" }, [
    _c("a", { staticClass: "navbar-item", attrs: { href: "/" } }, [
      _c("img", { attrs: { src: _vm.logo, width: "62", height: "62" } })
    ]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "navbar-burger",
        attrs: {
          role: "button",
          "data-target": "mainNavbar",
          "aria-label": "menu",
          "aria-expanded": "false"
        }
      },
      [
        _c("span", { attrs: { "aria-hidden": "true" } }),
        _vm._v(" "),
        _c("span", { attrs: { "aria-hidden": "true" } }),
        _vm._v(" "),
        _c("span", { attrs: { "aria-hidden": "true" } })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }