<template>

  <div
    class="lesson-list-item"
    :class="status"
  >
    <div class="lesson-list-item-image">
      <div
        class="background-image lesson-responsive-image"
        v-bind:style="{ 'background-image': 'url(' + cover + ')' }"
      ></div>
    </div>
    <div class="lesson-list-item-detail">
      <p class="has-text-primary is-uppercase">{{ level }}</p>
      <p class="title has-text-primary">{{ title }}</p>
      <p>{{ excerpt }}</p>
      <p class="has-text-centered">
        <br>
        <a
          title="Disabled"
          disabled="disabled"
          class="button is-rounded release-date"
        >To be released on {{ releasedate }}</a>
      </p>
    </div>
    <div class="lesson-list-item-action has-text-centered">
      <a
        :href="permalink"
        class="button is-medium is-primary is-rounded read-now"
      >
        <i class="mdi mdi-book-open-variant mdi-24px"></i>
        <span>Read</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "lesson-list-item",
  props: ["title", "permalink", "cover", "status", "level", "releasedate", "excerpt"]
};
</script>

<style lang="scss" scoped>
</style>
