import axios from "axios";

let baseURL;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  baseURL = "https://membership-keepers-alemartins.c9users.io/wp-json/wp/v2";
} else {
  //baseURL = "https://members.keepersoftheflame.org/wp-json/wp/v2";
  //baseURL = "https://miembros.guardianesdelallama.local/wp-json/wp/v2";
  baseURL = "https://miembros.guardianesdelallama.org/wp-json/wp/v2";
}

const apiClient = axios.create({
  baseURL: baseURL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

export default {
  getLesson(id) {
    return apiClient.get("/lecciones-kof/" + id);
  }
};
